import clsx from 'clsx'
import { FieldProps } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC, ReactElement, useMemo } from 'react'

import ErrorMessage from '@/components/ErrorMessage'
import Icon from '@/components/Icon'

import styles from './TextField.module.scss'

interface TextFieldProps extends FieldProps {
  className: string
  helper?: ReactElement | string
  label?: string
  placeholder?: string
}

const TextField: FC<TextFieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.,
  className = '',
  helper = null,
  label = null,
  placeholder = ' ', // здесь пробел нужен для корректной работы!
  ...refs
}) => {
  const { name } = field
  const { t } = useTranslation()

  const errorString = useMemo(() => errors[name] as string, [errors, name])

  const error = useMemo(() => {
    let error = errorString

    if (!error) {
      return null
    }

    if (error?.indexOf('errors:') === -1) {
      error = `errors:${error.toLowerCase().replace(/[\s]/g, '_')}`
    }
    return error
  }, [errorString])

  return (
    <div className={clsx(styles['text-field'], className)}>
      <label className={styles['text-field__label']}>
        <input
          className={clsx(styles['text-field__control'], {
            [styles['text-field__control_invalid']]: touched[name] && error
          })}
          placeholder={placeholder}
          {...field}
          {...refs}
        />
        {label && (
          <span className={styles['text-field__label-text']}>{label}</span>
        )}
      </label>
      {touched[name] && error && (
        <ErrorMessage
          before={<Icon name={'status/error1/16'} style={{ minWidth: 16 }} />}
          className={styles['text-field__error']}
        >
          <span dangerouslySetInnerHTML={{ __html: t(error, errorString) }} />
        </ErrorMessage>
      )}
      {helper && <div className={styles['text-field__helper']}>{helper}</div>}
    </div>
  )
}
export default TextField
