import { Field, Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC, useCallback } from 'react'

/*
import ReCAPTCHA from 'react-google-recaptcha'
*/
import * as Yup from 'yup'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import TextField from '@/components/TextField'

import FormikWithServerErrors from '@/utils/FormikWithServerErrors'

import styles from '../Forms.module.scss'

import { useUser } from '@/bus/user'
import { routes } from '@/config'

const validationSchema = Yup.object().shape({
  code: Yup.string().required('errors:code.required')
})

const Login2FAForm: FC = () => {
  const { t } = useTranslation()
  /* const captchaRef = useRef<ReCAPTCHA>(null)
  const [captchaKey, setCaptchaKey] = useState(null)*/

  const {
    login2FA,
    loading,
    errors: serverErrors = {},
    startLoading,
    cancel2FA
  } = useUser()

  /*useEffect(() => {
    // @ts-ignore
    setCaptchaKey(GOOGLE_CAPTCHA_SITE_KEY)
  }, [])*/

  const handleSubmit = useCallback(
    async (values: any) => {
      if (/*!captchaRef?.current ||*/ loading) {
        return
      }

      /* captchaRef.current.reset()
      captchaRef.current.execute()*/

      /*
      const token = await captchaRef.current.executeAsync()
*/

      /*if (!token) {
        return
      }*/

      startLoading()

      const data = {
        ...values
      }

      login2FA({ data })
    },
    [loading, login2FA, startLoading]
  )

  return (
    <>
      <div className={styles['heading']}>
        <Heading className={styles['heading__title']} level={3}>
          {t('auth:signin.2fa.heading')}
        </Heading>
        <div className={styles['heading__summary']}>
          {t('auth:signin.2fa.summary')}
        </div>
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ code: '' }}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnBlur
        validateOnChange
      >
        {({ setErrors }) => {
          return (
            <FormikWithServerErrors
              setErrors={setErrors}
              serverErrors={serverErrors}
            >
              <Form className={styles['form']} noValidate>
                {/*{captchaKey && (
                  <div className={styles['form__captcha']}>
                    <ReCAPTCHA
                      ref={captchaRef}
                      size="invisible"
                      sitekey={captchaKey}
                    />
                  </div>
                )}*/}
                <div>
                  <Field
                    label={t('forms:fields.code.label')}
                    className={styles['form__field']}
                    name="code"
                    type="text"
                    component={TextField}
                    maxLength={6}
                  />
                </div>

                <div className={styles['form__group-buttons']}>
                  <Button
                    title={t('forms:buttons.cancel') as string}
                    as={Link}
                    href={routes.HOME}
                    className={styles['form__button']}
                    variant={'light'}
                    size={'l'}
                    onClick={cancel2FA}
                  >
                    {t('forms:buttons.cancel')}
                  </Button>
                  <Button
                    title={t('forms:buttons.signin') as string}
                    className={styles['form__button']}
                    before={<Icon name={'arrows/right/28'} />}
                    type={'submit'}
                    size={'l'}
                    loading={loading}
                  >
                    {t('forms:buttons.signin')}
                  </Button>
                </div>
              </Form>
            </FormikWithServerErrors>
          )
        }}
      </Formik>
    </>
  )
}
export default Login2FAForm
